/* The side navigation menu */
.sidebar {
  margin: 0;
  padding: 0;
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
}



div.nav{
  height:40px;
}

@media (max-width: 767px) { 
    .navbar .navbar-brand{
        display:none;
    }
}

body {
  overflow:hidden;
}



